<template>
  <v-footer color="rgb(114, 151, 255)" class="footer">
    <v-row justify="center" no-gutters>
      <v-col cols="12" sm="12" md="1">
        <img :src="data.logo" class="image" target="_blank" />
      </v-col>

      <v-col cols="12" sm="12" md="2" v-for="icon in data.icons" :key="icon">
        <p style="color:#FFFFFF;" class="mx-3 Paragraph">
          <v-btn dark icon>
            <v-icon size="24px">
              {{ icon.icon }}
            </v-icon>
          </v-btn>
          {{ icon.name }}
        </p>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    icons: ["mdi-email", "mdi-phone", "mdi-instagram", "mdi-facebook"],
    data: []
  }),
  methods: {
    getSchoolData() {
      axios
        .get(this.getApiUrl + "/getSchoolData", {
          headers: {
            Authorization: "Bearer " + localStorage.token
            //the token is a variable which holds the token
          }
        })
        .then(response => {
          this.data = response.data.data;
        });
    }
  },
  mounted() {
    this.getSchoolData();
  }
};
</script>
<style scoped>
.Paragraph {
  font-size: 17px;
  padding: 20px;
}
.footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 30px;
  margin-top: 15px;
}
@media (max-width: 960px) {
  .Paragraph {
    font-size: 15px;
    padding: 0;
  }
  .image {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 0;
  }
  .footer {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
@media (max-width: 600px) {
  .Paragraph {
    font-size: 10px;
    padding: 0;
  }
  .image {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 0;
  }
  .footer {
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
  }
}
</style>
