<template>
  <div>
    <h3 style="margin-top: 15px" class="custom-header main-color">
      {{ $t("Checkout") }}
    </h3>
    <v-row v-if="data.total_after_discount == 0">
      <v-col cols="12">
        <v-alert class="info_alert">
          {{ $t("There are no invoices you need to pay") }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-card class="mx-auto">
          <v-card-title
            style="
              color: #4d4f5c;
              padding-top: 10px !important;
              padding-bottom: 30px;
              font-size: 22px;
            "
            >{{ $t("Payment Method") }}</v-card-title
          >

          <form
            action=""
            class="paymentWidgets"
            data-brands="VISA MASTER"
            id="FromHyperPay"
          ></form>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-card class="mx-auto">
          <v-card-title
            style="
              color: #4d4f5c;
              padding-top: 10px !important;
              padding-left: 23px !important;
              padding-bottom: 30px;
              font-size: 22px;
            "
            >{{ $t("Payment Summary") }}</v-card-title
          >
          <v-card-text>
            <v-row style="margin-top: -5px">
              <v-col cols="6" md="4"
                ><label>{{ $t("Student Name") }}</label></v-col
              >
              <v-col cols="6" md="8"
                ><p>{{ data.student_name }}</p></v-col
              >
            </v-row>
            <v-row style="margin-top: -20px">
              <v-col cols="6" md="4"
                ><label>{{ $t("Student ID") }}</label></v-col
              >
              <v-col cols="6" md="8"
                ><p>{{ data.student_code }}</p></v-col
              >
            </v-row>
            <v-row style="margin-top: -20px">
              <v-col cols="6" md="4"
                ><label>{{ $t("Grade") }}</label></v-col
              >
              <v-col cols="6" md="8"
                ><p>{{ data.student_grade }}</p></v-col
              >
            </v-row>
            <v-divider></v-divider>
            <v-row style="margin-top: -20px">
              <v-col cols="6" md="4"
                ><label>{{ $t("Total Amount") }}</label></v-col
              >
              <v-col cols="6" md="8"
                ><p>{{ data.total_before_tax }} {{ $t("SAR") }}</p></v-col
              >
            </v-row>
            <v-row style="margin-top: -20px">
              <v-col cols="6" md="4"
                ><label>{{ $t("Tax Amount") }}</label></v-col
              >
              <v-col cols="6" md="8"
                ><p>{{ data.total_tax_amount }} {{ $t("SAR") }}</p></v-col
              >
            </v-row>
            <v-row style="margin-top: -20px">
              <v-col cols="6" md="4"
                ><label>{{ $t("Discount") }}</label></v-col
              >
              <v-col cols="6" md="8"
                ><p>{{ data.total_discount }} {{ $t("SAR") }}</p></v-col
              >
            </v-row>
            <v-divider></v-divider>
            <v-row style="margin-top: -20px">
              <v-col cols="6" md="4"
                ><label
                  ><strong>{{ $t("Net") }}</strong></label
                ></v-col
              >
              <v-col cols="6" md="8"
                ><p>{{ data.total_after_discount }} {{ $t("SAR") }}</p></v-col
              >
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <FooterNotLogin v-if="!isLogin()"></FooterNotLogin>
  </div>
</template>

<script>
import axios from "axios";
import FooterNotLogin from "../../components/FooterNotLoginComponent";
export default {
  data() {
    return {
      shopperResultUrl: "",
      actionUrl: "",
      data: [],
    };
  },
  components: {
    FooterNotLogin,
  },
  methods: {
    isLogin() {
      return localStorage.login && localStorage.login == "true" ? true : false;
    },
    getData() {
      axios
        .post(
          this.getApiUrl + "/GetStudentPaymentDataAndId",
          {
            student_code: this.$router.currentRoute.params.code,
            isInvoice: this.$route.query.isInvoice,
            invoice_id: this.$route.query.invoiceId,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.data = response.data.data;
          this.shopperResultUrl =
            window.location.origin + "/paymentRedirect/" + this.data.parent_id;
          // console.log("callback =" + this.$route.query.callbackUrl);

          document.getElementById("FromHyperPay").action =
            this.shopperResultUrl;

          this.actionUrl =
            "https://oppwa.com/v1/paymentWidgets.js?checkoutId=" +
            this.data.checkout_id;

          let recaptchaScript = document.createElement("script");
          recaptchaScript.setAttribute("src", this.actionUrl);
          document.head.appendChild(recaptchaScript);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
label {
  color: #8d8a8a;
  font-size: 19px;
}
p {
  color: #bbbbbb;
  font-size: 16px;
}
.info_alert {
  /* margin-left: 23%;
  margin-top: 10%;
  margin-bottom: 13%; */
  color: rgba(0, 0, 0, 0.38);
}
</style>
